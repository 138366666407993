
export const NEW_ID = 'new';

export const MENU_ITEM_TYPE = {
    ACTION: 'ACTION',
    LINK: 'LINK',
    MENU: 'MENU',
};

export const ENTITY_TYPE = {
    ORGANIZATIONS: 'ORGANIZATIONS',
    TECHNOLOGY_CATEGORIES: 'TECHNOLOGY_CATEGORIES',
    REGULATIONS: 'REGULATIONS',
    INDUSTRIES: 'INDUSTRIES',
    DATA_TYPES: 'DATA_TYPES',
    USER_ROLES: 'USER_ROLES',
    AGREEMENTS: 'AGREEMENTS',
    LANGUAGES: 'LANGUAGES',
    COUNTRIES: 'COUNTRIES',
    STATES: 'STATES',
    USERS: 'USERS',
};

export const ORGANIZATION_TYPES = {
    VENDOR: 'Vendor',
    SUBSIDIARY: 'Subsidiary',
    ORGANIZATION: 'Organization',
};

// RegulatoryJurisdictionType
export const REGULATORY_JURISDICTION_TYPE = {
    NONE: 'NONE',
    US: 'US',
    US_STATE: 'US_STATE',
    EU: 'EU',
    INTERNATIONAL: 'INTERNATIONAL'
};

export const REGULATORY_ENTRY_LEVEL_EXEMPTIONS_MAP = {
    'NONPROFIT': 'Nonprofit',
    'COPPA': 'COPPA (Children\'s Online Privacy Protection Act)',
    'CONSUMER_REPORTING_AGENCY': 'Consumer Reporting Agency',
    'GOVERNMENT_ENTITIES': 'Government Entities',
    'HIGHER_EDUCATIONAL_INSTITUTIONS': 'Higher educational institutions (public or private)',
    'ELECTRIC_UTILITIES': 'Electric utilities, power generation companies, and retail electric providers',
    'FINANCIAL_INSTITUTIONS_GRAMM_LEACH_BLILEY_ACT': 'Financial institutions subject to Title V of the Gramm-Leach-Bliley Act',
    'INSURANCE_COMPANIES': 'Insurance companies',
    'FCRA': 'Fair Credit Reporting Act (FCRA)',
    'DPPA': 'Driver\'s Privacy Protection Act (DPPA)',
    'FERPA': 'Family Educational Rights and Privacy Act (FERPA)',
    'FCA': 'Farm Credit Act (FCA)',
    'ADA': 'Airline Deregulation Act (ADA)',
    'HITECH': 'HITECH Act',
    'HIPPA_COVERED_ENTITIES': 'HIPPA covered entities',
    'HIPAA_BUSINESS_ASSOCIATES': 'HIPAA business associates',
    'GRAMM_LEACH_BLILEY_ACT': 'Gramm-Leach-Bliley Act',
    'SMALL_BUISNESS': 'Small Buisness',
};

/*
Protected Health Information subject to HIPAA, health records, patient identifying information for the purposes of 42 U.S.C. § 290dd–2, information and documents created for purposes of the Health Care Quality Improvement Act of 1986, patient safety work product for purposes of the Patient Safety and Quality Improvement Act of 2005, or identifiable private information used in connection with human clinical trials and research.
Information originating from, and "intermingled to be indistinguishable with, or information treated in the same manner as, information exempt under this section" that is maintained by a HIPAA-covered entity or HIPAA-defined business associate.
Personal information covered by and/or processed in accordance with the Fair Credit Reporting Act, Driver's Privacy Protection Act, Family Educational Rights and Privacy Act of 1974, the Farm Credit Act of 1971, and several others.
Personal data processed by a person in the course of a purely personal or household activity.
Emergency contact information.
 */
export const REGULATORY_DATA_SPECIFIC_EXEMPTIONS_MAP = {
    'CRIMINAL_DATA': 'Criminal Data',
    'EMPLOYMENT_DATA': 'Employment Data',
    'PATIENT_IDENTIFYING_DATA': 'Patient Identifying Data',
    'PROTECTED_HEALTH_INFORMATION': 'Protected Health Information subject to HIPAA',
    'INFORMATION_MAINTAINED_BY_HIPAA': 'Information maintained by a HIPAA-covered entity or HIPAA-defined business associate',
    'PERSONAL_INFORMATION_COVERED_BY_ACTS': 'Personal information covered by and/or processed in accordance with the Fair Credit Reporting Act, Driver\'s Privacy Protection Act etc',
    'PERSONAL_DATA_OF_PERSONAL_OR_HOUSEHOLD': 'Electric utilities, power generation companies, and retail electric providers',
    'EMERGENCY_CONTACT_INFORMATION': 'Emergency contact information',
};

export const REGULATORY_TIME_UNITS = ['None', 'Quarterly', 'B_Annual', 'Annual', 'Every2years',];
export const REGULATORY_TIME_UNITS_MAP = {'None': 'None', 'Quarterly': 'Quarterly', 'B_Annual': 'B-Annual', 'Annual': 'Annual', 'Every2years': 'Every 2 years'};

export const REGULATORY_TYPES = ['STATE', 'US_FEDERAL', 'GLOBAL_STANDARD', 'INDUSTRY_SPECIFIC'];
export const REGULATORY_TYPES_MAP = {'STATE': 'State', 'US_FEDERAL': 'US Federal', 'GLOBAL_STANDARD': 'Global Standard', 'INDUSTRY_SPECIFIC': 'Industry Specific'};

// NONE, IG1, IG2, IG3
export const ASSESSMENT_FRAMEWORK_LEVEL = {
    'undefined': '',
    0:  'NONE',
    1:  'IG1',
    2:  'IG2',
    3:  'IG3',
};

export const TWO_FACTOR_AUTH_TYPES_MAP = {'TOTP': 'Authenticator Application (TOTP)', 'PHONE': 'Phone', };

export const ANALYTIC_TYPES = ['QLIK', 'POWER_BI', 'DASHBOARD', ];
export const ANALYTIC_TYPES_MAP = {'QLIK': 'qLik', 'POWER_BI': 'Power BI', 'DASHBOARD': 'RiskQ Dashboard', };

export const DOWNLOAD_TYPES = {
    ADMIN_LANGUAGE_CONSTANTS: {
        CSV_LIST: "ADMIN_LANGUAGE_CONSTANTS",
        CSV_TEMPLATE: "ADMIN_LANGUAGE_CONSTANTS"
    },
    ADMIN_PERMISSIONS: {
        CSV_LIST: "ADMIN_PERMISSIONS",
        CSV_TEMPLATE: "ADMIN_PERMISSIONS"
    },
    ADMIN_HINTS: {
        CSV_LIST: "ADMIN_HINTS",
        CSV_TEMPLATE: "ADMIN_HINTS"
    },
    ADMIN_NEWS: {
        CSV_LIST: "ADMIN_NEWS",
        CSV_TEMPLATE: "ADMIN_NEWS"
    },
    ADMIN_PACE_COURSES: {
        CSV_LIST: "ADMIN_PACE_COURSES",
        CSV_TEMPLATE: "ADMIN_PACE_COURSES"
    },
    ADMIN_WEBINARS: {
        CSV_LIST: "ADMIN_WEBINARS",
        CSV_TEMPLATE: "ADMIN_WEBINARS"
    },
    ADMIN_WHATS_NEW: {
        CSV_LIST: "ADMIN_WHATS_NEW",
        CSV_TEMPLATE: "ADMIN_WHATS_NEW"
    },

};

export const STATUS = {
    PENDING: 'PENDING',
    DELETED: 'DELETED',
    ENABLED: 'ENABLED',
    APPROVED: 'APPROVED',
    DISABLED: 'DISABLED',
    PUBLISHED: 'PUBLISHED',
    UNPUBLISHED: 'UNPUBLISHED',
};

export const PERMISSION = {
    RISK_MODEL: {
        READ: 'risk_model_read',
        CREATE: 'risk_model_create',
        UPDATE: 'risk_model_update',
        DELETE: 'risk_model_delete',
    },
    RISK_MODEL_CONSTANT: {
        READ: 'risk_model_constant_read',
        CREATE: 'risk_model_constant_create',
        UPDATE: 'risk_model_constant_update',
        DELETE: 'risk_model_constant_delete',
    },
    ORGANIZATION: {
        READ: 'organization_read',
        UPDATE: 'organization_update',
    },
    ORGANIZATION_SUPPORTED_LANGUAGES: {
        UPDATE: 'organization_supported_languages_update',
    },
    ORGANIZATION_CONTROL_TEST_RESULTS: {
        DOWNLOAD: 'organization_control_test_result_download',
    },
    SUBSIDIARY: {
        READ: 'subsidiary_read',
        CREATE: 'subsidiary_create',
        UPDATE: 'subsidiary_update',
        DELETE: 'subsidiary_delete',
    },
    BUSINESS_UNIT: {
        READ: 'business_unit_read',
        CREATE: 'business_unit_create',
        UPDATE: 'business_unit_update',
        DELETE: 'business_unit_delete',
    },
    ASSET_CLASS: {
        READ: 'asset_class_read',
        CREATE: 'asset_class_create',
        UPDATE: 'asset_class_update',
        DELETE: 'asset_class_delete',
    },
    INDUSTRY: {
        READ: 'industry_read',
        CREATE: 'industry_create',
        UPDATE: 'industry_update',
        DELETE: 'industry_delete',
    },
    QLIK_ANALYTIC: {
        READ: 'qlik_analytic_read',
        CREATE: 'qlik_analytic_create',
        UPDATE: 'qlik_analytic_update',
        DELETE: 'qlik_analytic_delete',
    },
    DATA_CLASS: {
        READ: 'data_class_read',
        CREATE: 'data_class_create',
        UPDATE: 'data_class_update',
        DELETE: 'data_class_delete',
    },
    HINT: {
        READ: 'hint_read',
        CREATE: 'hint_create',
        UPDATE: 'hint_update',
        DELETE: 'hint_delete',
    },
    REGULATION: {
        READ: 'data_class_read',
        CREATE: 'data_class_create',
        UPDATE: 'data_class_update',
        DELETE: 'data_class_delete',
    },
    QUANTIFICATION_MODELS: {
        READ: 'quantification_models_read',
        CREATE: 'quantification_models_create',
        UPDATE: 'quantification_models_update',
        DELETE: 'quantification_models_delete',
    },
    SYSTEM: {
        READ: 'system_read',
        CREATE: 'system_create',
        UPDATE: 'system_update',
        DELETE: 'system_delete',
    },
    PROCESS: {
        READ: 'process_read',
        CREATE: 'process_create',
        UPDATE: 'process_update',
        DELETE: 'process_delete',
    },
    TECHNOLOGY_CATEGORY: {
        READ: 'technology_category_read',
        CREATE: 'technology_category_create',
        UPDATE: 'technology_category_update',
        DELETE: 'technology_category_delete',
    },
    TECHNOLOGY: {
        READ: 'technology_read',
        CREATE: 'technology_create',
        UPDATE: 'technology_update',
        DELETE: 'technology_delete',
    },
    DATA_EXFILTRATION: {
        READ: 'data_exfiltration_read',
        UPDATE: 'data_exfiltration_update',
    },
    BUSINESS_INTERRUPTION: {
        READ: 'business_interruption_read',
        UPDATE: 'business_interruption_update',
    },
    REGULATORY_EXPOSURE: {
        READ: 'regulatory_exposure_read',
        UPDATE: 'regulatory_exposure_update',
    },
    RISK_SCORING: {
        READ: 'risk_scoring_read',
        QUESTION: 'risk_scoring_question',
        LIKELIHOOD_QUESTION: 'risk_scoring_likelihood_question',
        IMPACT_QUESTION: 'risk_scoring_impact_question',

    },
    ORGANIZATION_RISK_SCORING: {
        READ: 'organization_risk_scoring_read',
    },
    POLICY: {
        READ: 'policy_read',
        CREATE: 'policy_create',
        UPDATE: 'policy_update',
        DELETE: 'policy_delete',
    },
    GDPR_ORGANIZATION_COMPLIANCE: {
        READ: 'gdpr_organization_compliance_read',
        UPDATE: 'gdpr_organization_compliance_update',
        SCORING_QUESTION: 'gdpr_organization_compliance_scoring_question',
        IMPACT_QUESTION: 'gdpr_organization_compliance_impact_question',
        LIKELIHOOD_QUESTION: 'gdpr_organization_compliance_likelihood_question',
    },
    GDPR_SYSTEM_COMPLIANCE: {
        READ: 'gdpr_system_compliance_read',
        ARTICLE_READ: 'gdpr_system_article_compliance_read',
        ARTICLE_UPDATE: 'gdpr_system_article_compliance_update',
        SCORING_QUESTION: 'gdpr_system_compliance_scoring_question',
        IMPACT_QUESTION: 'gdpr_system_compliance_impact_question',
        LIKELIHOOD_QUESTION: 'gdpr_system_compliance_likelihood_question',
    },
    GDPR_EVIDENCE_DOCUMENT: {
        READ: 'gdpr_evidence_document_read',
        UPDATE: 'gdpr_evidence_document_update',
    },
    PRIVACY_IMPACT_ASSESSMENT: {
        READ: 'privacy_impact_assessment_read',
    },
    DOMAIN: {
        READ: 'domain_read',
        ADD: 'domain_add',
    },
    CATEGORY: {
        READ: 'category_read',
        CREATE: 'category_create',
        UPDATE: 'category_update',
    },
    QUANTIFICATION_MODEL: {
        READ: 'quantification_model_read',
    },
    QUANTIFICATION_METRIC: {
        READ: 'quantification_metric_read',
        CREATE: 'quantification_metric_create',
        UPDATE: 'quantification_metric_update',
        DELETE: 'quantification_metric_delete',
    },
    QUALITATIVE_METRIC: {
        READ: 'qualitative_metric_read',
        CREATE: 'qualitative_metric_create',
        UPDATE: 'qualitative_metric_update',
    },
    QUALITATIVE_QUESTION: {
        READ: 'qualitative_question_read',
        CREATE: 'qualitative_question_create',
        UPDATE: 'qualitative_question_update',
        DELETE: 'qualitative_question_delete',
    },
    VULNERABILITY: {
        READ: 'vulnerability_read',
        CREATE: 'vulnerability_create',
        UPDATE: 'vulnerability_update',
        DELETE: 'vulnerability_delete',
    },
    RISK_METRIC: {
        READ: 'risk_metric_read',
        CREATE: 'risk_metric_create',
        UPDATE: 'risk_metric_update',
        DELETE: 'risk_metric_delete',
    },
    RISK_ANSWER: {
        READ: 'risk_answer_read',
        UPDATE: 'risk_answer_update',
    },
    ASSOCIATE_MODEL: {
        READ: 'associate_model_read',
        CREATE: 'associate_model_create',
        UPDATE: 'associate_model_update',
        DELETE: 'associate_model_delete',
    },
    ASSESSMENT: {
        READ: 'assessment_read',
        CREATE: 'assessment_create',
        UPDATE: 'assessment_update',
        DELETE: 'assessment_delete',
    },
    CONTROL_MATURITY: {
        READ: 'control_maturity_read',
        CREATE: 'control_maturity_create',
        UPDATE: 'control_maturity_update',
        DELETE: 'control_maturity_delete',
    },
    ASSESSMENT_TYPE: {
        READ: 'assessment_type_read',
        CREATE: 'assessment_type_create',
        UPDATE: 'assessment_type_update',
        DELETE: 'assessment_type_delete',
    },
    SECURITY_REQUIREMENT: {
        READ: 'security_requirement_read',
        CREATE: 'security_requirement_create',
        UPDATE: 'security_requirement_update',
        DELETE: 'security_requirement_delete',
    },
    NIST_CSF_CATEGORY: {
        READ: 'nist_csf_category_read',
        CREATE: 'nist_csf_category_create',
        UPDATE: 'nist_csf_category_update',
        DELETE: 'nist_csf_category_delete',
    },
    CONTROL_TEST: {
        READ: 'control_test_read',
        CREATE: 'control_test_create',
        UPDATE: 'control_test_update',
        DELETE: 'control_test_delete',
    },
    CONTROL_GUIDELINE: {
        READ: 'control_guideline_read',
        CREATE: 'control_guideline_create',
        UPDATE: 'control_guideline_update',
        DELETE: 'control_guideline_delete',
    },
    CONTROL_TEST_RESULT: {
        READ: 'control_test_result_read',
        UPDATE: 'control_test_result_update',
    },
    SYSTEM_CONTROL_TEST_RESULT: {
        READ: 'system_control_test_result_read',
        UPDATE: 'system_control_test_result_update',
        DRILLDOWN: 'system_control_test_result_drilldown',
        DOWNLOAD: 'system_control_test_result_download',
    },
    ASSESSMENT_FINDING: {
        READ: 'assessment_finding_read',
        CREATE: 'assessment_finding_create',
        UPDATE: 'assessment_finding_update',
        DELETE: 'assessment_finding_delete',
    },
    VENDOR: {
        READ: 'vendor_read',
        CREATE: 'vendor_create',
        UPDATE: 'vendor_update',
        SCORING_QUESTION: 'vendor_scoring_question',
        INTERNAL_QUESTION: 'vendor_internal_question',
        IMPACT_QUESTION: 'vendor_impact_question',
        LIKELIHOOD_QUESTION: 'vendor_likelihood_question'

    },
    CLOUD_SCORING: {
        READ: 'cloud_scoring_read',
        QUESTION: 'cloud_scoring_question',
        IMPACT_QUESTION: 'cloud_scoring_impact_question',
        LIKELIHOOD_QUESTION: 'cloud_scoring_likelihood_question',
    },
    ASSOCIATE_SYSTEM: {
        READ: 'associate_system_read',
        UPDATE: 'associate_system_update',
    },
    TASKS: {
        READ: 'task_read',
        CREATE: 'task_create',
        UPDATE: 'task_update',
        DELETE: 'task_delete',
    },
    TASK_CATEGORIES: {
        READ: 'task_category_read',
        CREATE: 'task_category_create',
        UPDATE: 'task_category_update',
        DELETE: 'task_category_delete',
    },
    SECURITY_TOOL: {
        READ: 'security_tool_read',
        CREATE: 'security_tool_create',
        UPDATE: 'security_tool_update',
        DELETE: 'security_tool_delete',
    },
    CYBER_ROLE: {
        READ: 'cyber_role_read',
        CREATE: 'cyber_role_create',
        UPDATE: 'cyber_role_update',
        DELETE: 'cyber_role_delete',
    },
    OPERATIONAL_COST: {
        READ: 'operational_cost_read',
        CREATE: 'operational_cost_create',
        UPDATE: 'operational_cost_update',
        DELETE: 'operational_cost_delete',
    },
    CAPITAL_COST: {
        READ: 'capital_cost_read',
        CREATE: 'capital_cost_create',
        UPDATE: 'capital_cost_update',
        DELETE: 'capital_cost_delete',
    },
    VARIABLE_COST: {
        READ: 'variable_cost_read',
        CREATE: 'variable_cost_create',
        UPDATE: 'variable_cost_update',
        DELETE: 'variable_cost_delete',
    },
    SYSTEM_RISK_REDUCTION: {
        READ: 'system_risk_reduction_read',
        UPDATE: 'system_risk_reduction_update',
    },
    USER_SETTINGS: {
        READ: 'user_settings_read',
        UPDATE: 'user_settings_update',
    },
    USER: {
        READ: 'user_read',
        CREATE: 'user_create',
        UPDATE: 'user_update',
        DELETE: 'user_delete',
    },
    AUDIT_LOG: {
        READ: 'audit_log_read',
        DETAILS: 'audit_log_details',
    },
    EXPORT: {
        BUSINESS_UNIT: 'export_business_unit',
        BUSINESS_UNIT_TEMPLATE: 'export_business_unit_template',
        CONTROL_GUIDELINE: 'export_control_guideline',
        QUALITATIVE_QUESTION: 'export_qualitative_question',
        SYSTEM_RISK: 'export_system_risk',
        VENDOR: 'export_vendor',
        SUBSIDIARY: 'export_subsidiary',
        USER: 'export_user',
        GDPR_ARTICLE: 'export_gdpr_article',
        ASSESSMENT_FRAMEWORK: 'export_assessment_framework',
        SECURITY_REQUIREMENTS: 'export_security_requirements',
    },
    IMPORT: {
        BUSINESS_UNIT: 'import_business_unit',
        CONTROL_GUIDELINE: 'import_control_guideline',
        QUALITATIVE_QUESTION: 'import_qualitative_question',
        SYSTEM_RISK: 'import_system_risk',
        VENDOR: 'import_vendor',
        SUBSIDIARY: 'import_subsidiary',
        USER: 'import_user',
        GDPR_ARTICLE: 'import_gdpr_article',
        GDPR_EVIDENCE_ARTICLES: 'import_gdpr_evidence_articles',
        ASSESSMENT_FRAMEWORK: 'import_assessment_framework',
        SECURITY_REQUIREMENTS: 'import_security_requirements',
    },
    DASHBOARD: {
        MY_ASSIGNMENTS: 'dashboard_my_assignments',
        VENDOR: 'dashboard_vendor',
        ORGANIZATION: 'dashboard_organization',
        PRIVACY: 'dashboard_privacy',
        CLOUD: 'dashboard_cloud',
        M_AND_A: 'dashboard_m_and_a',
        CROWN_JEWEL_ASSET: 'dashboard_crown_jewel_asset',
        PRIVACY_RISK: 'dashboard_privacy_risk',
        CYBERSECURITY_TOOL_ROI: 'dashboard_cybersecurity_tool_roi',
        BUDGETING: 'dashboard_budgeting',
        BUDGET_SCENARIO_ANALYSIS: 'dashboard_budget_scenario_analysis',
        ADMIN: 'dashboard_admin',
        QUESTION_STATUS: 'dashboard_question_status',
        ASSIGNMENT_STATUS: 'dashboard_assignment_status',
        QUESTION_STATUS_REPORT: 'dashboard_question_status_report',
        BUSINESS_UNIT_QUESTION_STATUS: 'dashboard_business_unit_question_status',
        VENDOR_TYPE_REPORT: 'dashboard_vendor_type_report',
        VENDOR_STATUS: 'dashboard_vendor_status',
        VENDOR_SELF_ASSESSMENT: 'dashboard_vendor_self_assessment',
        TEST_ITEM: 'dashboard_test_item',
        RESIDUAL_CYBER_RISK: 'dashboard_residual_cyber_risk',
        PHI_DATA: 'dashboard_phi_data',
        ETL_SYSTEMS_EXPOSURES: 'dashboard_etl_systems_exposures',
        IOT_EXPOSURES: 'dashboard_iot_exposures',
        UNINSURABLE_EXPOSURES: 'dashboard_uninsurable_exposures',
        TASK: 'dashboard_task',
        RESOURCES: 'dashboard_resources',
        ASSESSMENT: 'dashboard_assessment',
        SYSTEM_QDPR: 'dashboard_organization_system_gdpr',
        RISK_REGISTER: 'dashboard_risk_register',
        ASSESSMENT_FINDINGS: 'dashboard_assessment_findings',
        CFO_CROWN_JEWEL_ASSET: 'dashboard_cfo_crown_jewel_asset',
        CFO_CYBERSECURITY_TOOL_ROI: 'dashboard_cfo_cybersecurity_tool_roi',
        CFO_M_AND_A: 'dashboard_cfo_m_and_a',
        CFO_ORGANIZATION: 'dashboard_cfo_organization',
        CFO_VENDOR: 'dashboard_cfo_vendor',
    }
};
