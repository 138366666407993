// outsource dependencies
import {connect} from 'react-redux';
import React, {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import {find, findIndex, get} from 'lodash';
import {change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper} from '@material-ui/core';

// local dependencies
import {EDIT} from '../types';
import Page from '../../../components/page';
import Input from '../../../components/md-input';
import Alert from '../../../components/alert-error';
import MdSwitch from '../../../components/md-switch';
import {instanceAPI} from '../../../services/api.service';
import Preloader, {TYPE} from '../../../components/preloader';
import SelectEntities from '../../../components/select-entities';
import {MdAsyncSelect, MdSelect} from '../../../components/md-select';
import {BackBtn, PrimaryBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {ENTITY_TYPE, NEW_ID, ORGANIZATION_TYPES} from '../../../constants/spec';
import {ORGANIZATION_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {Save} from "@material-ui/icons";

import noLogoImage from '../../../images/no-image-icon.png';

// config
const FORM_NAME = 'organizationEditForm';
export const changeField = (field, value) => change(FORM_NAME, field, value);
const SynchrozizeBtn = ({ isNew = false, ...attr }) => (<PrimaryBtn {...attr} tooltip="Synchronize">
    <Save/>&nbsp;Synchronize
</PrimaryBtn>);

/**
 * Form wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.organizations.edit }),
    dispatch => ({
        init: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null}),
    })
)(({ init, initialized, expectAnswer, errorMessage, clearError, match }) => {
    let isNew = match.params.id === NEW_ID;
    return (<Page init={()=>init(match.params.id)} initialized={initialized}>
        <Row className="top-indent-4 offset-bottom-2">
            <Col xs={12} md={8} mdOffset={2}>
                <Alert active message={errorMessage} onChange={clearError} />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12} md={10} mdOffset={1}>
                <Paper className="indent-5">
                    <h2 className="offset-bottom-4">
                        {isNew ? 'Create' : 'Edit'}&nbsp;organization&nbsp;
                        <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                    </h2>
                    <Breadcrumbs breadCrumbsMap={ ORGANIZATION_MAP }  />
                    <ConnectedForm isNew={isNew} />
                </Paper>
            </Col>
        </Row>
    </Page>);
});

const ConnectedForm = connect(
    state => ({
        back: state.organizations.edit.back,
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: state.organizations.edit.data,
        disabled: state.organizations.edit.expectAnswer,
    }),
    dispatch => ({
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeType: orgType => dispatch({type: EDIT.CHANGE_TYPE, orgType}),
        syncWithZoomInfo: formData => {
            dispatch({type: EDIT.SYNCHRONIZE_WITH_ZOOM_INFO, ...formData});
        }
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @public
     */
    validate: (values, {isNew}) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'Name is required';
        }
        // organizationType
        if (!values.organizationType) {
            errors.organizationType = 'Organization Type is required.';
        }
        // rootParent
        if (values.organizationType && values.organizationType !== ORGANIZATION_TYPES.ORGANIZATION && isNew && !values.rootParent) {
            errors.rootParent = 'Organization is required.';
        }
        return errors;
    }
})(({pristine, invalid, disabled, reset, handleSubmit, update, syncWithZoomInfo, isNew, change, back, formValues = {}, changeType}) => {
    let excludedId = formValues.id !== NEW_ID ? formValues.id : null;
    return (
        <form autoComplete="off" noValidate name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-4">
                <Col xs={3}>
                    <img alt="Organization Logo" src={formValues.logo ? formValues.logo : noLogoImage} height={80} />
                </Col>
                <Col xs={9}>
                    <Field
                        name="name"
                        component={Input}
                        disabled={disabled}
                        label={(<strong className="required-asterisk"> Name </strong>)}
                            />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        multiline
                        name="description"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> Description </strong>)}
                            />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        valueKey="value"
                        labelKey="value"
                        component={MdSelect}
                        name="organizationType"
                        disabled={!isNew || disabled}
                        simpleValue={value => value && ({value})}
                        onChange={(e, value) => changeType(value)}
                        label={(<strong className={isNew ? 'required-asterisk' : ''}> Type </strong>)}
                        options={[ORGANIZATION_TYPES.ORGANIZATION, ORGANIZATION_TYPES.VENDOR, ORGANIZATION_TYPES.SUBSIDIARY]}
                            />
                </Col>
                {formValues.organizationType && formValues.organizationType === ORGANIZATION_TYPES.ORGANIZATION && (
                    <Col xs={12} md={4} className="offset-bottom-4">
                        <Field
                            name="packagePlan"
                            valueKey="id"
                            labelKey="name"
                            disabled={disabled}
                            component={MdAsyncSelect}
                            label={(<strong>Package Plan</strong>)}
                            loadOptions={(name, done) => {
                                instanceAPI({
                                    method: 'post',
                                    url: 'admin/package-plans/filter',
                                    data: {page: 0, size: 6, filter: {name}},
                                }).then(({items}) => {
                                    let plans = [{id: null, name: 'None'}];
                                    plans.push(...items);
                                    done(plans);
                                }).catch(done.bind(null, []));
                            }}/>
                    </Col>
                )}
                {formValues.organizationType && formValues.organizationType === ORGANIZATION_TYPES.ORGANIZATION && (
                    <Col xs={12} md={2} className="offset-bottom-4">
                        <Field
                            type="number"
                            name="logoutAfterInactivityTimeInMinutes"
                            component={Input}
                            disabled={disabled}
                            label={(<strong> Auto logout (mins) </strong>)}
                        />
                    </Col>
                )}
            </Row>
            {/*Not organization fields (for vendor, subsidiary)*/}
            {formValues.organizationType && formValues.organizationType !== ORGANIZATION_TYPES.ORGANIZATION && (
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <SelectEntities
                            name="rootParent"
                            selected={[excludedId]}
                            disabled={!isNew || disabled}
                            type={ENTITY_TYPE.ORGANIZATIONS}
                            label={(<strong className={isNew ? 'required-asterisk' : ''}> Root parent </strong>)}
                                />
                    </Col>
                </Row>
            )}
            {/*Vendor fields*/}
            {formValues.organizationType === ORGANIZATION_TYPES.VENDOR && (<Fragment>
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <SelectEntities
                            name="owner"
                            labelKey="fullName"
                            disabled={disabled}
                            type={ENTITY_TYPE.USERS}
                            label={(<strong> Owner </strong>)}
                                />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} className="text-center">
                        <Field
                            color="primary"
                            fullWidth={false}
                            name="isCloudVendor"
                            component={MdSwitch}
                            disabled={disabled}
                            label={(<strong> Cloud vendor </strong>)}
                                />
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                        <Field
                            color="primary"
                            fullWidth={false}
                            disabled={disabled}
                            component={MdSwitch}
                            name="isServiceVendor"
                            label={(<strong> Service vendor </strong>)}
                                />
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                        <Field
                            color="primary"
                            fullWidth={false}
                            disabled={disabled}
                            component={MdSwitch}
                            name="isTechnologyVendor"
                            label={(<strong> Technology vendor  </strong>)}
                                />
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                        <Field
                            color="primary"
                            fullWidth={false}
                            disabled={disabled}
                            component={MdSwitch}
                            name="isSystemVendor"
                            label={(<strong> System vendor </strong>)}
                                />
                    </Col>
                </Row>
            </Fragment>)}
            {/*Subsidiary fields*/}
            {formValues.organizationType === ORGANIZATION_TYPES.SUBSIDIARY && (<Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        name="parent"
                        disabled={disabled}
                        selected={[excludedId]}
                        type={ENTITY_TYPE.ORGANIZATIONS}
                        label={(<strong> Parent </strong>)}
                            />
                </Col>
            </Row>)}
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        name="site"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> Website URL </strong>)}
                            />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="tel"
                        name="phone"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> Phone number </strong>)}
                            />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="number"
                        name="insuranceLimit"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> Insurance Limit </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="number"
                        name="cyberInsuranceDeductible"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> Cyber Insurance Deductible </strong>)}
                    />
                </Col>
        </Row>
            <LocationConnected />
            <Row>
                <Col xs={12} md={4} className="offset-bottom-4">
                    <Field
                        name="streetAddress1"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> Address №1 </strong>)}
                            />
                </Col>
                <Col xs={12} md={4} className="offset-bottom-4">
                    <Field
                        name="streetAddress2"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> Address №2 </strong>)}
                            />
                </Col>
                <Col xs={12} lg={4} className="offset-bottom-4">
                    <Field
                        name="zip"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> ZIP or Postal code </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        name="currency"
                        valueKey="id"
                        disabled={disabled}
                        component={MdAsyncSelect}
                        label={(<strong> Currency </strong>)}
                        getOptionLabel={option => `${get(option, 'name')} (${get(option, 'code')})`}
                        loadOptions={(name, done) => {
                            instanceAPI({
                                method: 'post',
                                url: 'currencies/filter',
                                data: { page: 0, size: 6, filter: { name } },
                            }).then(({items})=>done(items)).catch(done.bind(null, []));
                        }}/>
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        name="language"
                        valueKey="id"
                        labelKey="name"
                        disabled={disabled}
                        component={MdAsyncSelect}
                        label={(<strong> Language </strong>)}
                        loadOptions={(name, done) => {
                            instanceAPI({
                                method: 'post',
                                url: 'languages/filter',
                                data: { page: 0, size: 6, filter: { name } },
                            }).then(({items})=>done(items)).catch(done.bind(null, []));
                        }}/>
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                        <Field
                            name="industry"
                            disabled={disabled}
                            placeholder="Industry"
                            component={MdAsyncSelect}
                            label={(<strong> Industry </strong>)}
                            loadOptions={(name, done) => {
                                instanceAPI({
                                    method: 'post',
                                    url: 'industries/filter',
                                    data: { page: 0, size: 6, filter: { name } },
                                }).then(({items})=>done(items)).catch(done.bind(null, []));
                            }}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        name="taxId"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> TAX ID </strong>)}
                            />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        name="vatId"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> VAT number </strong>)}
                            />
                </Col>
                <Col xs={12} lg={4} className="offset-bottom-4">
                    <Field
                        name="status"
                        valueKey="id"
                        labelKey="name"
                        disabled={disabled}
                        component={MdAsyncSelect}
                        label={(<strong> Status </strong>)}
                        loadOptions={(name, done) => {
                            instanceAPI({
                                method: 'post',
                                url: 'statuses/filter',
                                data: { page: 0, size: 6, filter: { name } },
                            }).then(({items})=>done(items)).catch(done.bind(null, []));
                        }}/>
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        multiline
                        name="notes"
                        component={Input}
                        disabled={disabled}
                        label={(<strong> Notes </strong>)}
                            />
                </Col>
            </Row>
            {formValues.organizationType === ORGANIZATION_TYPES.ORGANIZATION && (<Fragment>
                <Row>
                    <Col xs={12} md={6} className="offset-bottom-4">
                        <Field
                            type="number"
                            name="averageRevenue"
                            component={Input}
                            disabled={disabled}
                            label={(<strong> Annual revenue </strong>)}
                                />
                    </Col>
                    <Col xs={12} md={6} className="offset-bottom-4">
                        <Field
                            type="number"
                            component={Input}
                            disabled={disabled}
                            name="qualThreshold"
                            label={(<strong> Risk threshold </strong>)}
                                />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} className="offset-bottom-4">
                        <Field
                            type="number"
                            component={Input}
                            disabled={disabled}
                            name="marketCapitalizationNumber"
                            label={(<strong> Market capitalization </strong>)}
                                />
                    </Col>
                    <Col xs={12} md={6} className="offset-bottom-4">
                        <Field
                            type="text"
                            component={Input}
                            name="recordPriceLimit"
                            style={{color: 'black'}}
                            disabled={disabled}
                            label={(<strong> Record Price Limit </strong>)}
                        />
                    </Col>
                    <Col xs={12} md={6} className="offset-bottom-4">
                        <Field
                            type="text"
                            component={Input}
                            name="maximumWrittenPremium"
                            style={{color: 'black'}}
                            disabled={disabled}
                            label={(<strong> Maximum Written Premium </strong>)}
                        />
                    </Col>
                    <Col xs={12} md={6} className="offset-bottom-4">
                        <Field
                            type="text"
                            component={Input}
                            name="maximumCarrierLimit"
                            style={{color: 'black'}}
                            disabled={disabled}
                            label={(<strong> Maximum Carrier Limit </strong>)}
                        />
                    </Col>
                </Row>
                <Row className="text-center offset-bottom-4 offset-top-4">
                    <Col xs={12} md={3}>
                        <Field
                            type="number"
                            component={Input}
                            name="zoomInfoId"
                            label={(<strong> Zoom Info Integration ID </strong>)}
                        />
                    </Col>
                    <Col xs={12} md={3} className="text-center offset-top-2">
                        <SynchrozizeBtn isNew={isNew} disabled={isNew||!formValues.zoomInfoId} onClick={() => {syncWithZoomInfo(formValues)}} />
                    </Col>
                    <Col xs={12} md={3}>
                        <Field
                            fullWidth={false}
                            disabled={disabled}
                            component={MdSwitch}
                            name="isPublicCompany"
                            label={(<strong> Public Company </strong>)}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <Field
                            fullWidth={false}
                            disabled={disabled}
                            component={MdSwitch}
                            name="useMultiFactorAuth"
                            label={(<strong> Multi-factor authentication </strong>)}
                        />
                    </Col>
                </Row>
                <Row className="text-center offset-bottom-4 offset-top-10">
                    <Col xs={12} md={6}>
                        <FieldArray
                            disabled={disabled}
                            name="supportedLanguages"
                            component={CheckboxGroup}
                            label={(<strong> Supported languages </strong>)}
                                />
                    </Col>
                </Row>
            </Fragment>)}
            <Row className="top-indent-4 offset-bottom-4">
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={disabled||pristine||invalid} />
                    &nbsp;&nbsp;&nbsp;
                    <ResetBtn disabled={disabled||pristine} onClick={reset} />
                    &nbsp;&nbsp;&nbsp;
                    <BackBtn disabled={disabled} back={back} />
                </Col>
            </Row>
        </form>
    )
}));

const LocationConnected = connect(
    state => ({
        cities: state.organizations.edit.cities,
        states: state.organizations.edit.states,
        values: state.form.organizationEditForm.values,
        disabled: state.organizations.edit.expectAnswer,
    }),
    dispatch => ({
        setupCountry: countryId => dispatch({ type: EDIT.SETUP_COUNTRY.REQUEST, countryId }),
        setupState: ( countryId, stateId ) => dispatch({ type: EDIT.SETUP_STATE, countryId, stateId }),
    })
)(({disabled, values, states, cities, setupCountry, setupState}) => (
    <Row>
        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
            <Field
                name="country"
                valueKey="id"
                labelKey="name"
                disabled={disabled}
                component={MdAsyncSelect}
                placeholder="Select country"
                label={(<strong> Country </strong>)}
                onChange={(e) => setupCountry(get(e, 'id', null))}
                loadOptions={(name, done) => {
                    instanceAPI({
                        method: 'post',
                        url: 'countries/filter',
                        data: { page: 0, size: 6, filter: { name } },
                    }).then(({items})=>done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
            <Field
                name="state"
                valueKey="id"
                labelKey="name"
                defaultOptions={states}
                component={MdAsyncSelect}
                placeholder="Select state"
                label={(<strong> State </strong>)}
                onChange={(e) => setupState(get(values, 'country.id', null), get(e, 'id', null))}
                disabled={disabled||!get(values, 'country.id', null)}
                loadOptions={(name, done) => {
                    instanceAPI({
                        method: 'post',
                        url: 'states/filter',
                        data: { page: 0, size: 6, filter: { name, countryId: get(values, 'country.id', null) } },
                    }).then(({items})=>done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} lg={4} className="offset-bottom-4">
            <Field
                name="city"
                valueKey="id"
                labelKey="name"
                defaultOptions={cities}
                component={MdAsyncSelect}
                placeholder="Select city"
                label={(<strong> City </strong>)}
                disabled={disabled||!get(values, 'country.id', null)||(Boolean(states.length)&&!get(values, 'state.id', null))}
                loadOptions={(name, done) => {
                    let filter = {
                        name,
                        stateId: get(values, 'state.id', null),
                        countryId: get(values, 'country.id', null),
                    };
                    instanceAPI({
                        method: 'post',
                        url: 'cities/filter',
                        data: { page: 0, size: 6, filter },
                    }).then(({items})=>done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
));


const CheckboxGroup = connect(
    state => ({availableLanguages: state.organizations.edit.availableLanguages}),
    null
)( ({ fields, availableLanguages }) => {
    const allValues = fields.getAll();
    const toggle = (event, option = {}) => {
        const key = findIndex(allValues, {id: option.id});
        if (event.target.checked) fields.push(option);
        else key !== -1 && fields.remove(key);
    };
    return (<FormControl component="fieldset">
        <FormLabel component="legend"> <strong> Supported languages </strong> </FormLabel>
        <FormGroup>
            {availableLanguages.map((option = {}, key) => (
                <FormControlLabel
                    key={key}
                    label={option.name}
                    checked={Boolean(find(allValues, {id: option.id}))}
                    control={<Checkbox style={{padding: '6px 12px'}} value={option.name} onChange={event => toggle(event, option)} />}
                        />
            ))}
        </FormGroup>
    </FormControl>);
});

